import {faArrowLeft, faArrowRight, faCheck, faEye, faShare, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import {formatDistanceToNow} from 'date-fns';
import {loader} from 'graphql.macro';
import jsonp from 'jsonp';
import {useCallback, useEffect, useState} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import {graphRequest} from '../global/util';
import {StateSubject, ToasterSubject} from '../observer/all';
import './BlogQueue.scss';
import {ConfirmButton} from './ConfirmButton';
const QueueQuery = loader('../graph/queue.query.graphql').loc.source.body;
const DequeueMutation = loader('../graph/dequeue.mutation.graphql').loc.source.body;
const PublishMutation = loader('../graph/publish.mutation.graphql').loc.source.body;

const PostModal = ({post, url, isActive, onClose, onDequeue}) => {
	useEffect(() => {
		if (isActive) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isActive]);
	if (isActive === false) return null;
	return <div className='post-modal modal'>
		<div className='modal-header'>
			<h2>Queued To: {post.to.name}</h2>
			<h3>Queued From: {post.from.name}</h3>
			{post.tags.length && <div className='rendered-tags'><pre >{post.tags.join('+')}</pre></div>}
			<div className='modal-header-buttons'>
				<ConfirmButton onConfirmation={onDequeue.bind(undefined, post)} className={'background-red white'} confirmationComponent={<FontAwesomeIcon icon={faCheck} />}>DELETE</ConfirmButton>
				<button className='background-grey white' onClick={onClose}>CLOSE</button>
			</div>
		</div>
		<div className='modal-inner'>
			<iframe src={url} frameBorder='0' />
		</div>
	</div>;
};

const Post = ({post, onDequeue, onPublish}) => {
	const [url, setUrl] = useState(undefined);
	const [isActive, setIsActive] = useState(false);
	useEffect(() => {
		jsonp(`https://www.tumblr.com/oembed/1.0?url=https://${post.from.name}.tumblr.com/post/${post.id}`, {}, (error, data) => {
			const d = document.createElement('div');
			d.innerHTML = data.response.html;
			const embed = d.getElementsByClassName('tumblr-post')[0];
			if (embed instanceof HTMLElement && embed.dataset && embed.dataset.href) {
				setUrl(embed.dataset.href);
			}
		});
	}, []);

	return <tr>
		<td>
			<a className='post-id' style={{
				fontWeight: 'bold'
			}} target='_blank' href={`https://${post.from.name}.tumblr.com/`} rel='noreferrer'>{post.from.name}</a>
		</td>
		<td>
			<a className='post-id' style={{
				fontWeight: 'bold'
			}} target='_blank' href={`https://${post.from.name}.tumblr.com/post/${post.id}`} rel='noreferrer'>{post.id}</a>
			{post.removeCaptions && <span className='label background-orange-light black'>Caption Removed</span>}
		</td>
		<td>
			<div className='button-group'>
				<ConfirmButton onConfirmation={onPublish.bind(undefined, post)} className={'background-blue white'} confirmationComponent={<FontAwesomeIcon icon={faCheck} />}><FontAwesomeIcon icon={faShare} /></ConfirmButton>
				<button className='background-grey white' onClick={() => {
					setIsActive(true);
				}}><FontAwesomeIcon icon={faEye} /></button>
				<ConfirmButton onConfirmation={onDequeue.bind(undefined, post)} className={'background-red white'} confirmationComponent={<FontAwesomeIcon icon={faCheck} />}><FontAwesomeIcon icon={faTrash} /></ConfirmButton>
				<PostModal url={url} post={post} isActive={isActive} onClose={() => {
					setIsActive(false);
				}} onDequeue={onDequeue} />
			</div>
		</td>
	</tr>;
};

export const BlogQueue = ({blog, shuffled}) => {
	const [pagination, setPagination] = useState({
		current: 1,
		cap: 1
	});
	const [posts, setPosts] = useState([]);
	const [recent, setRecent] = useState(undefined);
	const [count, setCount] = useState(0);
	const [state, setState] = useState({
		networking: false
	});

	const fetch = useCallback(async (page) => {
		if (state.networking) return;
		setState((state) => {
			return {
				...state,
				networking: true
			};
		});
		try {
			const data = await graphRequest(QueueQuery, {
				to: blog._id,
				page
			});
			if (data.data.queue) {
				setPosts(data.data.queue.posts);
				setCount(data.data.queue.count);
				setRecent(data.data.queue.recent);
				setPagination({
					current: page,
					cap: Math.ceil(data.data.queue.count / 10)
				});
				setState((state) => {
					return {
						...state,
						networking: false
					};
				});
			}
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, [state.networking]);

	const dequeue = useCallback(async (post) => {
		try {
			if (state.networking) return;
			setState((state) => {
				return {
					...state,
					networking: true
				};
			});
			const data = await graphRequest(DequeueMutation, {
				to: blog._id,
				post: post.id,
				group: post.group
			});
			ToasterSubject.next({
				type: 'success',
				message: 'Successfully deleted'
			});
			setCount((count) => {
				return count - 1;
			});
			return fetch(1);
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, []);

	const publish = useCallback(async (post, group) => {
		console.log(post, group);
		try {
			if (state.networking) return;
			setState((state) => {
				return {
					...state,
					networking: true
				};
			});
			const data = await graphRequest(PublishMutation, {
				to: blog._id,
				post,
				group
			});
			StateSubject.next();
			ToasterSubject.next({
				type: 'success',
				message: 'Successfully posted'
			});
			return fetch(1);
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		fetch(pagination.current);
	}, []);

	return <div className='blog-queue' style={{
		marginBottom: '20px'
	}}>
		<div className='grid' style={{
			marginBottom: '20px',
			lineHeight: '1em '
		}}>
			<div className='content'>
				<div className='column-5'>
					<div className='card background-cyan-light'>
						<h2>{count}</h2>
						<h3>Posts Queued</h3>
					</div>
				</div>
				<div className='column-5'>
					<div className='card background-green-light'>
						{recent ? <h2 style={{
							textTransform: 'capitalize'
						}}>{formatDistanceToNow(new Date(recent.updatedAt), {
								includeSeconds: true
							})} Ago</h2> : <h2>Never</h2>}
						<h3>Posted Last</h3>
					</div>
				</div>
			</div>
		</div>
		<div>
			<h1 style={{
				marginBottom: '20px'
			}}>Queue</h1>
			{shuffled && <div className='panel'>
				<h2>This Queue is Shuffled</h2>
			</div>}
			{!shuffled && <div className='posts'>
				{posts.length > 0 && <div className='button-group header'>
					<ConfirmButton className='background-blue white' onConfirmation={publish.bind(null, undefined, undefined)} confirmationComponent={'Publish Next Post'}><FontAwesomeIcon icon={faShare} /></ConfirmButton>
				</div>}
				<table className='posts-table'>
					<thead>
						<tr>
							<td>From</td>
							<td>ID</td>
							<td>Actions</td>
						</tr>
					</thead>
					<tbody>
						{state.networking && <tr>
							<td colSpan={3}>
								<div style={{
									display: 'flex',
									justifyContent: 'center'
								}}>
									<ClipLoader color={'black'} loading={true} size={150} />
								</div>
							</td>
						</tr>}
						{state.networking === false && posts.slice(0, 10).map((post, index) => {
							return <Post key={post.id + index} post={post} onDequeue={dequeue.bind(undefined, post)} onPublish={publish.bind(null, post.id, post.group)} />;
						})}
					</tbody>
				</table>
				<div className='button-group'>
					<button className='background-grey' disabled={pagination.current === 1} onClick={fetch.bind(null, pagination.current - 1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
					<span className='label'>Page {pagination.current} of {pagination.cap}</span>
					<button className='background-green-light' disabled={pagination.current === pagination.cap} onClick={fetch.bind(null, pagination.current + 1)}><FontAwesomeIcon icon={faArrowRight} /></button>
				</div>
			</div>}
		</div>
	</div>;
};
